import React from "react";
import { Navbar, Container, Nav, Offcanvas, Button } from "react-bootstrap";
import Logo from "../../Images/newsLogo.png";
import styles from "./Header.module.css";
import "./Header.css";
import { useNavigate } from "react-router-dom";

const Header = ({ dark }) => {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  const isAuthenticated = localStorage.getItem("token") && user;
  const navigate = useNavigate()
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.reload();
  };

  const handleDashboard = () => {
    navigate('/dashboard')
  }

  return (
    <div className={dark ? "bg-black" : ""}>
      <Navbar expand="lg" className="navbar-dark bg-body-tertiary" style={{ zIndex: "9999" }}>
        <Container fluid>
          <Navbar.Brand href="/">
            <img src={Logo} alt="Logo" className="w-24" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar" />

          <Navbar.Offcanvas
            id="responsive-navbar"
            aria-labelledby="responsive-navbar-label"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="responsive-navbar-label">
                <img src={Logo} className="w-24" alt="Logo" />
              </Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="/">Cars</Nav.Link>
                <Nav.Link href="/boats">Boats</Nav.Link>
                <Nav.Link href="/bikes">Bikes</Nav.Link>
                <Nav.Link href="/trucks">Trucks</Nav.Link>
                <Nav.Link href="/agriculture">Agriculture</Nav.Link>
                <Nav.Link href="/earth-moving">Earth Moving</Nav.Link>
                <Nav.Link href="/new">New</Nav.Link>
                <Nav.Link href="/used">Used</Nav.Link>
                <Nav.Link href="/car-rentals">Car Rentals</Nav.Link>
                <Nav.Link href="/dealers">Dealers</Nav.Link>
              </Nav>

              <div className="d-flex flex-column flex-lg-row align-items-center mt-3 mt-lg-0">
                {!isAuthenticated ? (
                  <>
                    <Nav.Link href="/signup">
                      <Button className={`${styles.navvBtn} me-2`}>
                        Sell Your Vehicle
                      </Button>
                    </Nav.Link>
                    <Nav.Link href="/login">
                      <Button variant="outline-warning">Login</Button>
                    </Nav.Link>
                  </>
                ) : (
                  <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
                    <Button onClick={handleDashboard} variant="outline-warning">
                      Dashboard
                    </Button>
                    <Button onClick={handleLogout} variant="outline-warning">
                      Logout
                    </Button>
                  </div>

                )}
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
