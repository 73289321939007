import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PayPalButtons } from "@paypal/react-paypal-js";

function SubscriptionOptions() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const subscriptions = [
    {
      id: "1",
      name: "Starter Showcase",
      duration: "1",
      price: 55,
      subscriptionType: "Starter Showcase",
      planId: "P-5WA2192944617473BM7SZPTA",
      features: [
        "Up to 25 vehicle listings",
        "Logo display on listings",
        "1 Featured listing (rotated weekly)",
        "1 Social media boost per month (Facebook & Instagram)",
        "Similar listings feature",
        "<strong style='color: #d6bd83; font-weight: 1000;'>2-weeks free trial</strong>",
      ],
    },
    {
      id: "2",
      name: "Growth Accelerator",
      duration: "1",
      price: 250,
      subscriptionType: "Growth Accelerator",
      planId: "P-1TN038002R837950NM7SZQ4Y",
      features: [
        "Up to 75 vehicle listings",
        "Get 2 Weeks Free Membership Trial",
        "Logo display on listings",
        "3 Featured listings (rotated weekly)",
        "2 Social media boosts per month (Facebook & Instagram)",
        "Similar listings feature",
        "Personalized dashboard",
        "Priority listing placement",
        "Monthly performance report",
      ],
    },
    {
      id: "3",
      name: "Dealership Dominator",
      duration: "1",
      price: 400,
      subscriptionType: "Dealership Dominator",
      planId: "P-0B169577D96120617M7SZR4Q",
      features: [
        "Logo display on listings and homepage carousel with direct link to dealership's page on BuySellCars",
        "Get 2 Weeks Free Membership Trial",
        "5 Featured listings (rotated weekly)",
        "3 Social media boosts per month (Facebook & Instagram)",
        "Similar listings feature",
        "Personalized dashboard",
        "Top priority listing placement",
        "CTR links to dealer's website (if applicable)",
        "Monthly performance report",
      ],
    },
  ];

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, []);

  const handleContinue = (subscription) => {
    console.log(subscription, 'the subscription selecer')
    setSelectedPlan(subscription);
    setShowModal(true);
  };
  const { user } = JSON.parse(localStorage.getItem("user"));

  console.log(user, 'the user detials here')

  return (
    <div className="bg-[url(./Images/car.png)] bg-no-repeat min-h-screen bg-cover bg-center flex items-center justify-center">
      <div className="mx-auto grid grid-cols-1 md:grid-cols-3 items-center gap-8 px-4">
        {subscriptions.map((subscription) => (
          <div
            key={subscription.id}
            className="bg-white rounded-lg p-4 flex flex-col min-h-[480px] justify-between gap-8"
          >
            <div>
              <h4 className="font-bold text-xl">{subscription.name}</h4>
              <div className="flex justify-between items-center pt-2 pb-8">
                <p className="text-2xl">
                  <span className="font-bold text-4xl">{subscription.duration}</span>/mo
                </p>
                <p className="text-2xl">
                  <span className="font-bold text-4xl">$</span>
                  {subscription.price.toFixed(2)}
                </p>
              </div>
              <ul className="md:w-full flex flex-col gap-2">
                {subscription.features.map((feature, index) => (
                  <li key={index} className="flex items-center gap-2">
                    <p dangerouslySetInnerHTML={{ __html: feature }}></p>
                  </li>
                ))}
              </ul>
            </div>
            <button
              onClick={() => handleContinue(subscription)}
              className="bg-[#d6bd83] text-white py-2 px-6 rounded-lg text-lg"
            >
              Continue
            </button>
          </div>
        ))}
      </div>

      {/* PayPal Subscription Modal */}
      {showModal && selectedPlan && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">
            <h2 className="text-xl font-semibold mb-4">Subscribe to {selectedPlan.name}</h2>

          
            <PayPalButtons
              style={{ layout: "vertical", color: "blue", shape: "pill", label: "subscribe" }}
              createOrder={(data, actions) => {

                return actions.order.create({
                  purchase_units: [{
                    amount: {
                      value: selectedPlan.price,
                      currency_code: "USD",
                    },
                    description: selectedPlan.name,
                  }],
                  application_context: {
                    no_shipping: "1",
                  },
                  payer: {
                    email_address: user.email || "" 
                  }
                });

              }}
              onApprove={(data, actions) => {
                console.log(data, 'the data here');
                alert(`Subscription Successful! Order ID: ${data.orderID}`);

                // Send order ID to backend
                fetch(`${process.env.REACT_APP_BASE_URL}/payment/pay`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": `${localStorage.getItem("token")}`,
                  },
                  body: JSON.stringify({
                    userID: user._id,
                    orderID: data.orderID,
                    subscriptionType: selectedPlan.subscriptionType,
                    amount: selectedPlan.price
                  }),
                }).then(() => {
                  alert("Subscription saved!");
                  setShowModal(false);
                  navigate("/dashboard"); // Redirect after subscription
                });
              }}
            />



            <button
              className="mt-4 bg-red-500 text-white py-2 px-6 rounded-lg w-full"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SubscriptionOptions;






// const calculateDiscountedPrice = (basePrice, duration) => {
//   switch (duration) {
//     case "3 Months":
//       return (basePrice * 3 * 0.95).toFixed(2); // 5% discount
//     case "6 Months":
//       return (basePrice * 6 * 0.9).toFixed(2); // 10% discount

//     case "12 Months":
//       return (basePrice * 12 * 0.85).toFixed(2); // 10% discount
//     default:
//       return (basePrice * 1).toFixed(2); // No discount
//   }
// };

{/* <h4 className="font-semibold text-[#d6bd83] mt-4 mb-2">
                  Select Duration for discount packages
                </h4>
                <select
                  name="duration"
                  id="duration"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  className="border border-solid border-zinc-400 rounded-lg p-2"
                >
                  <option value="1 Month">1 Month (No discount)</option>
                  <option value="3 Months">3 Months (5% discount)</option>
                  <option value="6 Months">6 Months (10% discount)</option>
                  <option value="12 Months">12 Months (15% discount)</option>
                </select> */}